import { FC, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';
import { LANGUAGES } from '../Settings'

interface LanguagePopupProps {
  isOpen: boolean,
  close: () => void
}

interface LanguageElementsProps {
  closePopup: () => void
}

const PopupBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 30;
  background-color: rgba(0, 0, 0, .6);

  &.closed {
    display: none;
  }
`;

const PopupWrapper = styled.div`
  position: fixed;
  z-index: 30;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 600px;
  min-height: 300px;
  color: ${p => p.theme.textColor};
  background-color: ${p => p.theme.backgroundColor};
  border-radius: 5px;
  transition: top 0.2s linear;
  box-shadow: -8px -1px 52px -4px rgba(0, 0, 0, .3);

  &.closed {
    top: 150%;
    box-shadow: none;
  }
`

const Title = styled.span`
  text-align: center;
  padding: 30px;
  font-size: 30px;
  letter-spacing: .05em;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
`

const LanguageWrapper = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 20px 25px;
`

const Icon = styled.img`
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid rgba(0, 0, 0, .1);
  width: 90px;
  height: 90px;
  object-fit: cover;
  cursor: pointer;
`;

const Language = styled.li`
  float: left;
  display: none;
  margin: 0 10px;
  min-width: 130px;

  &.visible {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.active > img {
    box-shadow: 0 0px 0px 2px ${p => p.theme.accentColor};
  }

  &.active span {
    font-weight: bold;
  }
`

const LangName = styled.span`
  display: block;
  text-align: center;
  font-size: 1.1em;
  margin-top: 10px;
`

/**
 * Render the language selector popup, used on the home page
 * @returns {JSX.Element} Component template
 */
const LanguageElements: FC<LanguageElementsProps> = ({closePopup}) => {
  const { currentLanguage, setCurrentLanguage, deviceLanguages } = useContext(AppContext);
  
  const updateLanguage = useCallback((language: string) => {
    setCurrentLanguage(language);
    setTimeout(() => {
      closePopup();
    }, 200);
  }, [closePopup, setCurrentLanguage]);

  return (
    <>
      {deviceLanguages?.map((item, i) => {
        let langItem: any = LANGUAGES?.filter(lang => { 
          if (lang?.key === item) { return lang; } else { return null; }
        });

        if (langItem?.[0]?.key) {
          return (
            <Language key={`language-${i}`} className={`visible ${langItem?.[0]?.key === currentLanguage && "active"}`} onClick={() => updateLanguage(langItem?.[0]?.key)}>
              <Icon src={langItem?.[0]?.icon}/>
              <LangName>{langItem?.[0]?.name}</LangName>
            </Language>)
        } else {
          return null;
        }
      })}
    </>
  )
}

const LanguagePopup: FC<LanguagePopupProps> = ({isOpen, close}) => {
  return (
    <>
      <PopupBackdrop className={isOpen ? "open" : "closed"} onClick={close}/>
      <PopupWrapper className={isOpen ? "open" : "closed"}>
        <Title>Select language</Title>
        <LanguageWrapper>
          <LanguageElements closePopup={close}/>
        </LanguageWrapper>
      </PopupWrapper>
    </>
  )
}
export default LanguagePopup;