import { FC, useCallback } from 'react';
import styled from 'styled-components';
import BlockContainer from './BlockContainer';

interface BlockPrefaceProps {
  text: string,
  linebreaks?: boolean
}

const Wrapper = styled.div`
  clear: both;
  width: 100%;
  font-size: 1.5em;
  line-height: 1.3em;
  margin-bottom: 10px;
  text-align: center;
`;

/**
 * Render an article preface
 * @returns {JSX.Element} Component template
 */
const BlockPreface: FC<BlockPrefaceProps> = ({text, linebreaks}) => {

  const convertBreaksToBr = useCallback((text: string) => {
    return text.replace(/\n/g, "<br />");
  }, []);

  if (text && text.length > 0) { 
    return (
      <BlockContainer>
        <Wrapper>
          {linebreaks ? (
            <div dangerouslySetInnerHTML={{__html: convertBreaksToBr(text)}}></div>
          ) : (
            <>{text}</>
          )}
        </Wrapper>
      </BlockContainer>
    );
  }

  return null;
}

export default BlockPreface;