import i18next from "i18next";
import { CREDITS, WEBP_SUPPORT } from "../Settings";

/**
 * Parse credits to be used below media objects and in lightbox modals
 * @param {object} credits Credit list from the media API
 * @returns {string} Stringifies list
 */
 export const parseCredits = (credits: any) => {
  const creditTypes = CREDITS;

  let creditList: any = [];
  credits?.forEach((credit, i) => {
    if (credit?.name) {
      creditList.push(`${i18next.t(creditTypes[credit?.credit_type_id])}: ${credit?.name}`);
    }
  });
  return creditList?.join(", ");
}

/**
 * Fade in image when loaded
 * @param e ChangeEvent
 */
 export const showImageWhenLoaded = (e: React.ChangeEvent<HTMLImageElement>) => {
  e.target.classList.remove("loading");
  e.target.style.opacity = "1";
  e.target.style.visibility = "visible";
}

/**
 * Generate CSS filters from object
 * @param filters Filters. Example: { contrast: 1, brightness: 0.5 }
 * @returns {string} Filter CSS. Example: "contrast(1) brightness(0.5)"
 */
 export const generateCSSFilters = (filters: any) => {
  if (!filters) { return undefined; }
  
  let cssFilter: string[] = [];

  for (const prop in filters) {
    if (filters?.[prop]) {
      cssFilter?.push(`${prop}(${filters?.[prop]})`);
    }
  }

  return cssFilter?.join(" ");
}

/**
 * Get image URL with media type parameter
 * @param url {string} URL
 * @param options {string} Options
 * @returns Image URL with media type
 */
export const getImageUrl = (url: string, options?: { transparency?: boolean, dimension?: string }) => {
  // Force use of EMS
  url = verifyEmsUrl(url);

  if (!WEBP_SUPPORT && options?.transparency === true) {
    if (url.includes("?")) {
      url += `&mediaType=${"image/png"}`
    } else {
      url += `?mediaType=${"image/png"}`
    }
  } else if (!WEBP_SUPPORT) {
    if (url.includes("?")) {
      url += `&mediaType=${"image/jpg"}`
    } else {
      url += `?mediaType=${"image/jpg"}`
    }
  }

  if (options?.dimension) {
    if (url.includes("?")) {
      url += `&dimension=${options?.dimension}`
    } else {
      url += `?dimension=${options?.dimension}`
    }
  }

  return url;
}

/**
 * Force use of EMS
 * @param url Url to check
 * @returns {string} Verified URL
 */
export const verifyEmsUrl = (url: string) => {
  url = url.replace("http://", "https://");
  url = url.replace("https://mm.dimu.org", "https://ems.dimu.org");
  url = url.replace("https://dms02.dimu.org", "https://ems.dimu.org");
  url = url.replace("https://dms-test.dimu.org", "https://ems-test.dimu.org");

  return url;
}

/**
 * Verify if the webp image format is supported
 * @returns {boolean} Returns false if not supported
 */
export const verifyWebpSupport = () => {
 const elem = document.createElement('canvas');

  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  } else {
    return false;
  }
}

/**
 * Get dimension of image
 * @param {string} src Image url
 * @returns {object} Width and height of image
 */
export const getImageDimensions = (src: string) => {
  return new Promise((resolve, reject) => {
    //resolve({ width: 600, height: 300 })
    const img = new Image()
    img.onload = () => resolve({width: img.width * 3, height: img.height * 3})
    img.onerror = reject
    img.src = src + `?dimension=800x800`
  })
}