import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom'
import { AppContext } from '../contexts/AppContext';
import styled from 'styled-components';
import Header from '../framework/Header';
import BackgroundImage from '../components/BackgroundImage';
import ListItem from '../components/ListItem';
import { getTranslation } from '../common/Translation';
import PageTransition from '../components/PageTransition';
import { useTranslation } from 'react-i18next';
import ErrorMessageView from '../components/ErrorMessage';
import useFetchDocument from '../hooks/useFetchDocument';

const ListWrapper = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  list-style: none;
  margin: 0 ${p => p.theme.scaleFactor * 70}px ${p => p.theme.scaleFactor * 90}px;
  padding: ${p => p.theme.scaleFactor * 140}px 0 0px;
  display: grid;
  align-items: center;
  justify-items: center;
  align-content: space-evenly;
  grid-template-areas: none;
  grid-auto-flow: column dense;
  gap: 12px;
  scroll-snap-type: x mandatory;
  touch-action: pan-x;
  overflow: hidden;
  height: calc(100% - 60px);

  &.snapScroll {
    overflow: scroll;
    @supports not (-webkit-touch-callout: none) {
      height: calc(100%);
    }
  }

  &.snapScroll li:nth-child(2n+1) {
    scroll-snap-align: start;
  }

  &.snapScroll::-webkit-scrollbar {
    @supports not (-webkit-touch-callout: none) {
      height: 90px;
    }
  }

  &.snapScroll::-webkit-scrollbar-thumb {
    border-bottom: 50px solid transparent;
    border-top: 30px solid transparent;
    background-color: ${p => p.theme.accentColor};
    background-clip: padding-box;
  }

  &.snapScroll::-webkit-scrollbar-track {
    margin-left: 0;
    margin-right: 0;
  }
`

interface ListViewProps {
  previewData?: any,
  overrideTitle?: string
}

/**
 * Render a list of articles or media objects
 * @returns {JSX.Element} Component template
 */
const ListView: FC<ListViewProps> = ({previewData=null, overrideTitle}) => {
  const { t } = useTranslation();
  const { documentId } = useParams<{documentId: string}>();
  const { currentLanguage } = useContext(AppContext);
  const listRef = useRef<HTMLUListElement>(null);
  const [columnCount, setColumnCount] = useState(3);
  const [rowCount, setRowCount] = useState(2);
  const [titleColor, ] = useState("#ffffff");
  const [subtitleColor, ] = useState("#ffffff");
  const [titleSize, ] = useState("1.5em");
  const [subtitleSize, ] = useState("1em");
  const [textPosition, ] = useState("bottom-left");
  const [textBackground, ] = useState("shadow");
  const [data, error] = useFetchDocument(documentId, previewData);

  // Get the grid size. Can be received as a string ("2x3") or an object ({rows: 2, columns: 3})
  useEffect(() => {
    if (typeof data?.content?.settings?.grid?.gridSize === "string") {
      const gridSize = data?.content?.settings?.grid?.gridSize;
      setColumnCount(Number(gridSize?.split("x")?.[0] || columnCount))
      setRowCount(Number(gridSize?.split("x")?.[1] || rowCount))
    } else {
      setRowCount(data?.content?.settings?.grid?.rows || rowCount);
      setColumnCount(data?.content?.settings?.grid?.columns || columnCount);
    }
  }, [data?.content?.settings, columnCount, rowCount]);

  // Do layout adjustments based on items count
  useEffect(() => {
    const itemsCount = data?.content?.records?.list?.length;
    let rows = 0, cols = 0;

    if (listRef.current) {
      if (itemsCount <= 3) {
        rows = 1; cols = itemsCount;
      } else if (itemsCount === 4) {
        rows = 2; cols = 2;
      } else if (itemsCount === 5 || itemsCount === 6) {
        rows = 2; cols = 3;
      } else if (itemsCount > 6) {
        rows = 2; cols = Math.ceil(itemsCount/2);
        listRef.current.classList.add("snapScroll");
      }

      listRef.current.style.gridTemplateRows = `repeat(${rows}, calc(${100/rows}% - ${rows * 3}px))`;
      listRef.current.style.gridTemplateColumns = `repeat(${cols}, calc(${100/Math.min(cols, 3)}% - 8px))`;
    }
  }, [data?.content]);

  // Display error message if no content is loaded
  if (error) { return <ErrorMessageView title={t("presentationNotFound.title")} body={t("presentationNotFound.body")} redirect={true}></ErrorMessageView> }

  // Only display if published
  if (data?.status && data?.status !== "published") { return <ErrorMessageView title={t("presentationNotPublished.title")} body={t("presentationNotPublished.body")} redirect={true}/> }

  return (
    <>
      <Header title={getTranslation(overrideTitle, currentLanguage) ||(data?.content?.general?.showTitle ? getTranslation(data?.content?.general?.title, currentLanguage) : "")} backgroundColor={data?.content?.background?.backgroundColor}></Header>
      <PageTransition waitFor={data}>
      <BackgroundImage media={data?.content?.background?.image} backgroundColor={data?.content?.background?.backgroundColor} />
        <ListWrapper ref={listRef}>
          {data?.content?.records?.list?.map((item, i) => {
            return <ListItem key={`listìtem-${i}`} documentId={item?.to_document_id} image={item?.content?.image} title={item?.content?.title} subtitle={item?.content?.subtitle} cellSize={item?.content?.cellSize || "1x1"} textPosition={textPosition} titleColor={titleColor} subtitleColor={subtitleColor} titleSize={titleSize} subtitleSize={subtitleSize} textBackground={textBackground}/>
          })}
        </ListWrapper>
      </PageTransition>
    </>
  );
}

export default ListView;
