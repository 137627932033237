import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LightboxWrapper, LightboxItem } from './LightboxModal'
import Image from './Image';
import { getTranslation } from '../common/Translation';
import { AppContext } from '../contexts/AppContext';
import { parseCredits } from '../common/Media';
import BlockContainer from './BlockContainer';
import useFetchMedia from '../hooks/useFetchMedia';
import PlayIconSvg from "../assets/icon_play.svg";
import BlockError from './BlockError';
import { useTranslation } from 'react-i18next';

interface BlockMediaWrapperProps {
  layout: "full" | "thumbs" | "slide",
  lightbox?: boolean,
  lightboxOptions?: any,
  children: JSX.Element | JSX.Element[]
}

interface BlockMediaItemProps {
  media?: any,
  lightbox?: boolean,
  caption?: string,
  credits?: string,
  onClick?: () => void
}

const Wrapper = styled.ul`
  list-style: none;
  padding: 0 50px;
  margin: 0 0 20px;
  float: left;
  width: 100%;
  display: block;
  position: relative;

  &:empty {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & img {
    border-radius: 4px;
  }

  & li img:active {
    filter: brightness(0.9);
  }

  &.thumbs {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
    color: #333;
    padding: 0;
    margin: 20px 0;
    gap: 12px;

    & > span {
      display: none;
    }

    @media (min-width: 450px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 600px) {
      grid-template-columns: repeat(4, 1fr);
    }

    & li {
      display: grid;
      align-content: space-between;
      width: 100%;
      padding: 15px;
      margin: 0;
      background-color: #ffffff15;
      color: #fff;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, .2);
      border-radius: 8px;
    }

    & figure {
      float: initial;
    }

    & img {
      height: 180px;
      object-fit: contain;
      border-radius: 2px;
    }

    & span {
      text-align: left;
      margin-top: 15px;
    }
  }

  &.slide {
    overflow-x: auto;
    display: flex;
    align-items: flex-start;
    overflow-x: auto;
    flex: 0 0 auto;

    & li {
      width: auto;
      margin: 20px 10px;
      width: min-content;
    }

    & li:first-child {
      margin-left: auto;
    }

    & li:last-of-type {
      margin-right: auto;
    }

    & img {
      height: 300px;
      width: auto;
      object-fit: cover;
    }
  }

  &.full li:not(:last-of-type) {
    margin-bottom: 3vw;
  }

  &.full {
    padding: 0;
  }

  &.full li {
    width: 100%;
    float: none;
    gap: 25px;
    clear: both;
    margin: 0 auto;
    overflow: auto;
    background-color: #ffffff02;
  }

  &.full li span {
    padding-top: 5px;
  }

  &.full figure {
    width: 100%;
    margin: 0 auto;

    & img { 
      width: 100%; 
      /*min-height: 300px;*/
      max-height: 75vh;
      min-height: 35vh;
      object-fit: contain;
      transition: min-height .2s;
    }
  }
`;

const ImageItem = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 0;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  & figure {
    margin: 0;
    display: inline;
    height: max-content;
    position: relative;
    float: left;
  }

  &.placeholder figure {
    background: ${p => p.theme.placeholderColor} !important;
  }

  &.video figure::before, &.sketchfab figure::before, &.model3d figure::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: ${p => p.theme.accentColor};
    background-image: url(${PlayIconSvg});
    background-position: 60% 50%;
    background-size: 30px;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 10;
  }
`;

const ImageCaption = styled.span`
  font-size: 1em;
  display: block;
  margin-top: .5vw;
  --max-lines: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  float: left;
  clear: both;
  word-break: break-word;
`;

const ImageCredits = styled.span`
  font-size: .85em;
  display: block;
  margin-top: 5px;
  --max-lines: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  float: left;
  clear: both;
  word-break: break-word;
`;

const ImageWrapper = styled.div`
  margin: 0 auto;
  /*width: fit-content;*/
`;

const ImageItemWrapper = styled.li`
  padding: 0;
  margin: 0;
  pointer-events: all;
`


/**
 * Render an image/video block container with lightbox functionality
 * @returns {JSX.Element} Component template
 */
export const BlockMediaWrapper: FC<BlockMediaWrapperProps> = ({layout, lightbox, lightboxOptions, children}) => {  

  // Display as lightbox
  if (lightbox) {
    return (
      <BlockContainer className={layout}>
        <Wrapper className={layout}>
          <LightboxWrapper options={lightboxOptions}>
            {children}
          </LightboxWrapper>
        </Wrapper>
      </BlockContainer>
    );
  } else {
    // Display without lightbox
    return (
      <BlockContainer className={layout}>
        <Wrapper className={layout}>
          {children}
        </Wrapper>
      </BlockContainer>
    );
  }
}

const PlaceholderText = styled.div.attrs((p: any) => ({
  width: p.width || 100,
}))`
  aspect-ratio: 16 / 1;
  background: ${p => p.theme.placeholderColor};
  display: inline-block;
  width: -webkit-fill-available;
  height: 13px;
  border-radius: 2px;
  width: ${p => p.width}%;
`;

/**
 * Render an image/video block item with lightbox functionality
 * @returns {JSX.Element} Component template
 */
export const BlockMediaItem: FC<BlockMediaItemProps> = ({media, caption, credits, lightbox, onClick}) => {
  const { currentLanguage, previewIsActive } = useContext(AppContext);
  const { t } = useTranslation();
  const [itemMedia] = useFetchMedia(media, { dimension: "max" });
  const [mediaTitle, setMediaTitle] = useState<string>();
  const [mediaDescription, setMediaDescription] = useState<string>();
  const [mediaCredits, setMediaCredits] = useState<string>();

  // Set media captions and credits
  useEffect(() => {
    setMediaTitle(getTranslation(itemMedia?.title, currentLanguage) || caption || "");
    setMediaDescription(getTranslation(itemMedia?.description, currentLanguage || ""));
    setMediaCredits(parseCredits(itemMedia?.credits));
  }, [itemMedia, caption, currentLanguage]);

  // Construct media item
  const mediaItem = (
    <ImageItem className={itemMedia?.media_type} onClick={onClick}>
      {itemMedia && (<Image className="loading link" media={itemMedia} dimensions='max'/>)}
    </ImageItem>
  );

  // Display placeholder while loading
  if (!itemMedia) { 
    return (
      <ImageItem className="placeholder">
        <Image/>
        <ImageCaption><PlaceholderText width="50"/></ImageCaption>
      </ImageItem>
    )
  }

  // Disable youtube and vimeo
  if (itemMedia?.media_type === "youtube" || itemMedia?.media_type === "vimeo") {
    if (previewIsActive) {
      return <ImageItemWrapper><BlockError text={t("block.notSupported")}/></ImageItemWrapper>
    } else {
      return null;
    }
  }

  // Render media item - with or without lightbox wrapper
  if (lightbox) {
    switch (itemMedia?.media_type) {
      case "video":
      case "image":
      case "dm":
      case "model3d":
      case "sketchfab":
        return (
          <ImageItemWrapper>
            <LightboxItem media={itemMedia} caption={mediaTitle} credits={mediaCredits}>
              <ImageWrapper>
                {mediaItem}
              </ImageWrapper>
            </LightboxItem>
            <ImageCaption>
              {mediaTitle !== "" && (<strong>{mediaTitle}</strong>)}
              {(mediaTitle !== "" && mediaDescription !== "") && (<> – </>)}
              {mediaDescription !== "" && (<>{mediaDescription}</>)}
            </ImageCaption>
            {mediaCredits !== "" && (<ImageCredits>{mediaCredits}</ImageCredits>)}
          </ImageItemWrapper>
        );

      default:
        return mediaItem;
    }
  } else {
    return (
      <ImageItemWrapper>
        {mediaItem}
        <ImageCaption>
          {mediaTitle !== "" && (<strong>{mediaTitle}</strong>)}
          {(mediaTitle !== "" && mediaDescription !== "") && (<> – </>)}
          {mediaDescription !== "" && (<>{mediaDescription}</>)}
        </ImageCaption>
        {mediaCredits !== "" && (<ImageCredits>{mediaCredits}</ImageCredits>)}
      </ImageItemWrapper>
    )
  }
}