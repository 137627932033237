import { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigationType } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext';

const useRememberScrollPosition = (data, elementRef, scrollPosition) => {
  const navigationType = useNavigationType();
  const {scrollHistory, setScrollHistory} = useContext(AppContext);
  const [isBackNavigation, setIsBackNavigation] = useState(false);

  // Update scroll history
  const updateScrollHistory = useCallback(() => {
    const currentId = data?.id || "root";
    const currentScroll = scrollHistory[currentId] || {};

    if (currentScroll.x === scrollPosition.current.x && currentScroll.y === scrollPosition.current.y) {
      return
    }

    if (scrollPosition?.current?.x !== null && scrollPosition?.current?.y !== null) {
      setScrollHistory(prevValues => ({
        ...prevValues,
        [data?.id || "root"]: {x: scrollPosition?.current?.x || 0, y: scrollPosition?.current?.y || 0}
      }));

      setScrollHistory({[currentId]: {x: scrollPosition?.current?.x || 0, y: scrollPosition?.current?.y || 0}});
    }
  }, [scrollHistory, setScrollHistory, scrollPosition, data?.id]);

  // Revert scroll history for elementRef if available
  const revertScrollHistory = useCallback(() => {
    if (isBackNavigation && elementRef.current) {
      elementRef.current.scrollLeft = Number(scrollHistory?.[data?.id || "root"]?.x || 0);
      elementRef.current.scrollTop = Number(scrollHistory?.[data?.id || "root"]?.y || 0);
    }
  }, [isBackNavigation, elementRef, scrollHistory, data?.id]);

  // Scroll to previous position if back button was used to navigate here
  useEffect(() => {
    const timer = setTimeout(() => { revertScrollHistory(); }, 100)

    // Store scroll position when components demounts
    return () => {
      clearTimeout(timer);
      updateScrollHistory();
    }
  }, [revertScrollHistory, updateScrollHistory]);

  useEffect(() => {
    if (navigationType === 'POP') {
      setIsBackNavigation(true);
    } else {
      setIsBackNavigation(false);
    }
  }, [navigationType]);

  return isBackNavigation;
};

export default useRememberScrollPosition;
