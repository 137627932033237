import { useEffect, useRef, useState } from 'react';

function useIdleTimer(timeout: number, onIdle: () => void) {
    const [isIdle, setIsIdle] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const resetTimer = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setIsIdle(true);
            onIdle();
        }, timeout);
        setIsIdle(false);
    };

    useEffect(() => {
        const handleUserInteraction = () => resetTimer();

        // Legg til event listeners
        window.addEventListener('mousemove', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('click', handleUserInteraction);
        window.addEventListener('scroll', handleUserInteraction);

        // Start timeren første gang
        resetTimer();

        // Fjern event listeners ved opprydding
        return () => {
            window.removeEventListener('mousemove', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('scroll', handleUserInteraction);

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
        // eslint-disable-next-line
    }, [timeout, onIdle]);

    return isIdle;
}

export default useIdleTimer;
