import { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { AppContext } from '../contexts/AppContext';
import styled from 'styled-components';
import { getTranslation } from '../common/Translation';
import Header from '../framework/Header';
import PageTransition from '../components/PageTransition';
import { useTranslation } from 'react-i18next';
import ErrorMessageView from '../components/ErrorMessage';
import React from 'react';
import BlockText from '../components/BlockText';
import BlockQuote from '../components/BlockQuote';
import { BlockAudioItem, BlockAudioWrapper } from '../components/BlockAudio';
import { BlockMediaItem, BlockMediaWrapper } from '../components/BlockMedia';
import BlockPreface from '../components/BlockPreface';
import BlockTitle from '../components/BlockTitle';
import useFetchDocument from '../hooks/useFetchDocument';
import useFetchMedia from '../hooks/useFetchMedia';
import BlockDMSearch from '../components/BlockDM_Search';
import BlockDMSingle from '../components/BlockDM_Single';
import BlockAnchor from '../components/BlockAnchor';
import AnchorNavigation from '../components/AnchorNavigation';
import BlockImageCompare from '../components/BlockMedia_Compare';
import BlockTasks from '../components/BlockTasks';
import { BlockByline } from '../components/BlockByline';
import HeroImage from '../components/HeroImage';
import { BlockMinne } from '../components/BlockMinne';
import BlockWidget from '../components/BlockWidget';
import BlockImageSlideshow from '../components/BlockMedia_Slideshow';
import { BlockDocumentItem, BlockDocumentsWrapper } from '../components/BlockDocuments';
import { BlockExternalLinkItem, BlockExternalLinksWrapper } from '../components/BlockLinks_External';
import { BlockInternalLinkItem, BlockInternalLinksWrapper } from '../components/BlockLinks_Internal';

interface ArticleViewProps {
  previewData?: any,
  overrideTitle?: string
}

const ImagePlaceholder = styled.div`
  width: 100%;
  height: ${p => p.theme.scaleFactor * 80}px;
  float: left;
`;

const Article = styled.article`
  position: relative;
  padding: ${p => p.theme.scaleFactor * 60}px 0 0;
  margin: 0 auto;
  z-index: 1;

  .no

  &.preview * {
    pointer-events: none;
  }
`;

const Blocks = styled.div`
  margin-top: 50px;
`;

const Title = styled.h1`
  font-size: 3em;
  font-weight: normal;
  text-align: center;
  padding: 0 50px;
  max-width: ${p => p.theme.blockWidth};
  margin: 0 auto ${p => p.theme.blockMargin};
  pointer-events: none;
`;

/**
 * Render a single article
 * @returns {JSX.Element} Component template
 */
const ArticleView: FC<ArticleViewProps> = ({previewData=null, overrideTitle}) => {
  const { t } = useTranslation();
  const { documentId } = useParams<{documentId: string}>();
  const { previewIsActive, currentLanguage } = useContext(AppContext);
  const [data, error] = useFetchDocument(documentId, previewData);
  const [mainImage] = useFetchMedia(data?.content?.general?.image);
  const [articleTitle, setArticleTitle] = useState<string>();

  useEffect(() => {
    if (overrideTitle) {
      setArticleTitle(getTranslation(overrideTitle, currentLanguage));
    } else {
      setArticleTitle(getTranslation(data?.content?.general?.title, currentLanguage));
    }
  }, [data, overrideTitle, currentLanguage]);

  // Display error message if no content is loaded
  if (error) { return <ErrorMessageView title={t("presentationNotFound.title")} body={t("presentationNotFound.body")} redirect={true}></ErrorMessageView> }

  // Only display if published
  if (data?.status && !data?.status?.includes("published")) { return <ErrorMessageView title={t("presentationNotPublished.title")} body={t("presentationNotPublished.body")} redirect={true}/> }

  return (
    <>
      <Header title={!data?.content?.general?.image?.mediaId ? articleTitle : undefined}/>
      <PageTransition waitFor={data} scroll={true}>
        {data?.content?.general?.image?.mediaId && (<HeroImage media={mainImage}/>)}
        
        <Article id="article" className={previewIsActive ? "preview" : undefined}>
          {!data?.content?.general?.image?.mediaId && (<ImagePlaceholder/>)}
          {data?.content?.general?.image?.mediaId && (<Title>{articleTitle}</Title>)}
          <BlockPreface text={getTranslation(data?.content?.general?.preface, currentLanguage)} linebreaks/>
          
          <AnchorNavigation blocks={data?.content?.content?.blocks?.[currentLanguage || "no"]}/>

          <Blocks>
            {data?.content?.content?.blocks?.[currentLanguage || "no"]?.map((block: any, i: number) => {

              switch(block?.blockType) {
                case "text":
                  return (
                    <React.Fragment key={`block${i}`}>
                      {block?.showTitle && (<BlockTitle text={block?.title}/>)}
                      <BlockText text={block?.body}/>
                    </React.Fragment>
                  );

                  case "media":
                    let mediaBlockLayout = block?.layout;
                    if (!mediaBlockLayout || mediaBlockLayout === "default") { 
                      if (block?.mediaItems?.length > 1) {
                        mediaBlockLayout = "slide";
                      } else {
                        mediaBlockLayout = "full";
                      }
                    }

                    return (
                      <React.Fragment key={`block${i}`}>
                        {block?.showTitle && (<BlockTitle text={block?.title}/>)}
                        {(() => { 
                          switch(mediaBlockLayout) {
                            case "compare":
                              return <BlockImageCompare elements={block?.mediaItems}/>;
                            case "slide":
                              return <BlockImageSlideshow elements={block?.mediaItems}/>
                            default:
                              return <BlockMediaWrapper layout={mediaBlockLayout} lightbox={true}>
                              {block?.mediaItems?.map((item: any, j: number) => {
                                return <BlockMediaItem key={`media${j}`} media={item} lightbox={true}/>  
                              })}
                            </BlockMediaWrapper>
                          }
                        })()}
                      </React.Fragment>
                    )

                case "audio":
                  return (
                    <React.Fragment key={`block${i}`}>
                      {block?.showTitle && (<BlockTitle text={block?.title}/>)}
                      <BlockAudioWrapper>
                        {block?.audioItems?.map((item: any, j: number) => {
                          return <BlockAudioItem key={`media${j}`} media={item} />  
                        })}
                      </BlockAudioWrapper>
                    </React.Fragment>
                  )

                case "dm":
                  return (
                    <React.Fragment key={`block${i}`}>
                      {block?.showTitle && (<BlockTitle text={block?.title}/>)}
                      {block?.dmBlockType === "search" ? (
                        <BlockDMSearch search={block?.dmSearch}/>
                      ) : (
                        <>
                          {Symbol.iterator in Object(block?.dmBlocks) && block?.dmBlocks?.map((dmItem: any, j: number) => {
                            return <BlockDMSingle key={`dmblock${j}`} element={dmItem}/>
                          })}
                        </>
                      )}
                    </React.Fragment>
                  )

                case "minne":
                  return (
                    <React.Fragment key={`block${i}`}>
                      {block?.showTitle && (<BlockTitle text={block?.title} level="h2"/>)}
                      <BlockMinne topicId={block?.memoria?.topicId || block?.topicId} domain={block?.memoria?.domain} sortOrder={block?.sortOrder || ""} resultCount={block?.resultCount}/>
                    </React.Fragment>
                  )

                case "quote":
                  return (
                    <React.Fragment key={`block${i}`}>
                      {block?.showTitle && (<BlockTitle text={block?.title}/>)} 
                      <BlockQuote quote={block?.quote} byline={block?.byline}/>
                    </React.Fragment>
                  )

                case "tasks":
                  return (
                    <React.Fragment key={`block${i}`}>
                      {block?.showTitle && (<BlockTitle text={block?.title}/>)} 
                      <BlockTasks taskList={block?.taskItems}/> 
                    </React.Fragment>
                  )

                case "byline":
                  return (
                    <React.Fragment key={`block${i}`}>
                      <BlockByline data={block?.byline}/> 
                    </React.Fragment>
                  )

                case "anchor":
                  return (
                    <React.Fragment key={`block${i}`}>
                      <BlockAnchor id={`anchor${i}`}/>
                      {block?.showTitle && (<BlockTitle text={block?.title} level="anchor"/>)}  
                    </React.Fragment>
                  )

                case "widget":
                  return (
                    <React.Fragment key={`block${i}`}>
                      {block?.showTitle && (<BlockTitle text={block?.title}/>)} 
                      <BlockWidget html={block?.code}/> 
                    </React.Fragment>
                  )

                case "documents":
                  return (
                    <React.Fragment key={`block${i}`}>
                      {block?.showTitle && (<BlockTitle text={block?.title}/>)}  
                      <BlockDocumentsWrapper>
                        {block?.documentItems?.map((doc, j) => {
                          return <BlockDocumentItem key={`pdf${j}`} media={{mediaId: doc?.mediaId}} />  
                        })}
                      </BlockDocumentsWrapper>
                    </React.Fragment>
                  )
                
                case "links":
                  return (
                    <React.Fragment key={`block${i}`}>
                      {block?.showTitle && (<BlockTitle text={block?.title}/>)}
                      {block?.linkType === "internal" ? (
                        <BlockInternalLinksWrapper>
                          {block?.references?.map((link, j) => {
                            return <BlockInternalLinkItem key={`link${j}`} link={link}/>
                          })}
                        </BlockInternalLinksWrapper>
                      ) : (
                        <BlockExternalLinksWrapper>
                          {block?.links?.map((link, j) => {
                            return <BlockExternalLinkItem key={`link${j}`} url={link?.url} title={link?.title} />  
                          })}
                        </BlockExternalLinksWrapper>
                      )}
                    </React.Fragment>
                  )
              
                case "3d":
                  return (
                    <React.Fragment key={`block${i}`}>
                      {block?.showTitle && (<BlockTitle text={block?.title}/>)}  

                      {block?.["3dItems"]?.map((item, j) => {
                      return <BlockMediaWrapper key={`media${j}`} layout="full" lightbox={true} lightboxOptions={{closeOnVerticalDrag: false, bgOpacity: .9, allowPanToNext: false}}>
                         <BlockMediaItem media={item} lightbox={true}/>  
                       </BlockMediaWrapper>
                    })}
                    </React.Fragment>
                  )
              }

              return null;
            })}
          </Blocks>
        </Article>
      </PageTransition>
    </>
  );
}

export default ArticleView;
