import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Kulturio from '@ekultur/kulturio-bridge';
import styled from 'styled-components';
import { AUTOPLAY_IMAGE_TIMER, MEDIA_PREFIX } from '../Settings'
import { fetchDocument, fetchMedia } from '../services/Api';
import { getTranslation } from '../common/Translation';
import { useTranslation } from 'react-i18next';
import ErrorMessageView from '../components/ErrorMessage';
import PageTransition from '../components/PageTransition';
import Header from '../framework/Header';
import { AppContext } from '../contexts/AppContext';
import useFetchDocument from '../hooks/useFetchDocument';
import PlayIconSvg from "../assets/icon_play.svg";
import PdfViewer from '../components/PdfViewer';
import Model3dPopup from '../components/LightboxModal_3D';

const Image = styled.img.attrs((p: any) => ({
  objectFit: p.objectFit || "contain",
  height: p.height || "100%"
}))`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: ${p => p.height};
  opacity: 1;
  object-fit: ${p => p.objectFit};
  transition: opacity .5s;
  background: #000;

  &.loading {
    opacity: 0;
  }
`

const Video = styled.video.attrs((p: any) => ({
  objectFit: p.objectFit || "contain",
  height: p.height || "100%"
}))`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: ${p => p.height};
  opacity: 1;
  object-fit: ${p => p.objectFit};
  transition: opacity .5s;
  background: transparent;
  filter: brightness(0.99);

  &.loading {
    opacity: 0;
  }

  &::cue {
    color: #fff;
    line-height: 80px;
  }
`

const Screenshot = styled.canvas.attrs((p: any) => ({
  objectFit: p.objectFit || "contain",
  height: p.height || "100%"
}))`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: ${p => p.height};
  opacity: 1;
  object-fit: ${p => p.objectFit};
  background: transparent;
`;

const PlayContainer = styled.div`
  &:before {
    content: "";
    opacity: 0;
    transition: opacity: 2s;
    transition-delay: 1s;
  }

  &.paused:before {
    content: "";
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: ${p => p.theme.accentColor};
    background-image: url(${PlayIconSvg});
    background-position: 60% 50%;
    background-size: 30px;
    background-repeat: no-repeat;
    pointer-events: none;
    opacity: 1;
  }
`;

const ProgressBarWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 10px;
  background-color: ${p => p.theme.backgroundColor};

  &::after {
    position: absolute;
    width: 100%;
    height: 100px;
    top: 50%;
    left: 0;
    content: "";
    transform: translateY(-50%);
  }
`

const ProgressBar = styled.div.attrs((p: any) => ({
  color: p.color || p.theme.accentColor
}))`
  width: 0%;
  height: 10px;
  background-color: ${p => p.color};
  transition: width 200ms;
`

const Iframe = styled.iframe`
  position: absolute; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
`;

interface AutoPlayViewProps {
  previewData?: any,
  overrideTitle?: string
}

/**
 * Render autoplay view
 * @returns {JSX.Element} Component template
 */
const AutoPlayView: FC<AutoPlayViewProps> = ({previewData=null, overrideTitle}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const screenshotRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<any>();
  const videoRef = useRef<any>();
  const playbuttonRef = useRef<any>();
  const timeout = useRef<NodeJS.Timeout>();
  const progressRef = useRef<HTMLDivElement>(null);
  const progressWrapperRef = useRef<HTMLDivElement>(null);
  const { deviceId, setMediaIsPlaying, currentLanguage, globalVolume, previewIsActive, setHideHeader } = useContext(AppContext);
  const { documentId } = useParams<{documentId: string}>();
  const [mediaQueue, setMediaQueue] = useState<any>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentFile, setCurrentFile] = useState<any>({});
  const [useOriginalVideoFiles, setUseOriginalVideoFiles] = useState<boolean>(false);
  const [data, error] = useFetchDocument(documentId, previewData);
  const [closedCaptions, setClosedCaptions] = useState<any>(null);
  const [keyboardTriggerIsActive, setKeyboardTriggerIsActive] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number|undefined>(undefined);
  const returnAfterPlay = !/device\/(\d+)(\/)?$/.test(window?.location?.hash);

  // Get height of content from Kulturio Desktop config
  useEffect(() => {
    const height = Kulturio.getConfig("interface.contentHeight");
    height && setContentHeight(height);
  }, []);

  /**
   * Capture thumbnail from video and provide is into the canvas element
   */
  const captureThumbnail = useCallback(() => {
    if (screenshotRef.current && videoRef.current) {
      const video = videoRef.current;
      
      // Only perform this if video has started
      if (video.currentTime > 0.1) {
        const canvas = screenshotRef.current;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = screenshotRef.current.getContext('2d');
        if (ctx) {
          ctx.imageSmoothingEnabled = video.imageSmoothingEnabled;
          ctx.imageSmoothingQuality = video.imageSmoothingQuality;
          ctx.globalAlpha = 1;
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        }
      }
    }
  }, []);

  /**
   * Disable running subtitles
   */
  const removeAllSubtitles = useCallback(() => {
    if (!videoRef.current) { return }
    const textTracks = videoRef.current.textTracks;
    for (var i = 0; i < textTracks.length || 0; i++) {
      textTracks[i].mode = 'disabled';
    }
  }, []);

  useEffect(() => {
    setMediaIsPlaying(true);
  }, [setMediaIsPlaying]);

  // Get captions
  useEffect(() => {
    if (!currentFile || !currentLanguage) { return }

    if (currentFile?.closedCaptions?.[currentLanguage || "no"]) {
      const encoder = new TextEncoder();
      const uint8Array = encoder.encode(currentFile?.closedCaptions?.[currentLanguage || "no"]);
      const vttBase64 = btoa(String.fromCharCode.apply(null, Array.from(uint8Array)));

      setClosedCaptions(`data:text/vtt;charset=utf-8;base64,${vttBase64}`);
    } else {
      setClosedCaptions(null);
    }
  }, [currentLanguage, currentFile]);

  // Play video on click. Fallback if browser is not supporting autoplay
  const playVideo = useCallback(() => {
    document?.querySelector("video")?.play();
  }, []);

  // Get presentation data from the API
  useEffect(() => {
    if (data?.content?.settings?.display?.useOriginalMedia === "true") {
      setUseOriginalVideoFiles(true);
    }
  }, [data, setUseOriginalVideoFiles]);

  // Create queue of media files, containing media source URL and media type
  useEffect(() => {
    let isSubscribed = true;
    if (!data?.content) { return; }

    (async() => {
      let mediaIds: number[] = [];

      // Get list of all media objects
      if (data?.content?.media) {
        for (const mediaItem of data?.content?.media) {
          mediaIds.push(mediaItem?.mediaId);
        }
      }

      if (mediaQueue.length === mediaIds.length && mediaIds.length > 0) { return }

      // Get media objects from articles
      if (data?.content?.records?.list) {
        for (const articleItem of data?.content?.records?.list) {
          // Fetch all articles
          await fetchDocument(articleItem?.to_document_id).then(articleData => {
              // Loop through all blocks
              if (articleData?.content?.content?.blocks?.[currentLanguage || "no"]) {
                articleData?.content?.content?.blocks?.[currentLanguage || "no"]?.forEach((block) => {
                  if (block?.blockType === "media") {
                    // Loop through all media items in media blocks
                    block?.mediaItems?.forEach((mediaBlockItem: any) => {
                      mediaIds.push(mediaBlockItem?.mediaId);
                    });
                  }
                });
              }
          });
        }
      }

      // Fetch all media URLs and store in mediaQueue
      if (isSubscribed && mediaIds) {
        let mediaQueueCache: any[] = [];
        for (const mediaId of mediaIds) {
          await fetchMedia(mediaId, {includeOriginalUrl: useOriginalVideoFiles}).then(mediaMetadata => {
            mediaQueueCache.push({
              src: MEDIA_PREFIX + (useOriginalVideoFiles ? mediaMetadata?.originalUrl || mediaMetadata?.src : mediaMetadata?.src),
              thumbnail: MEDIA_PREFIX + mediaMetadata?.thumbnail_src,
              mediaType: mediaMetadata?.media_type,
              closedCaptions: mediaMetadata?.closed_captions,
              volume: mediaMetadata?.volume
            });
          });
        }

        setMediaQueue(mediaQueueCache);
      }
    })();

    return () => { isSubscribed = false; }
  }, [data, useOriginalVideoFiles, currentLanguage, mediaQueue.length]);

  // Get the current file
  useEffect(() => {
    if (currentFile?.src !== mediaQueue?.[currentIndex]?.src) {
      setCurrentFile(mediaQueue?.[currentIndex]);
    }
  }, [currentIndex, mediaQueue, currentFile])

  /**
   * Increment current media index
   */
  const incrementIndex = useCallback(() => {
    let mediaCount = Object.keys(mediaQueue)?.length;

    if (returnAfterPlay && currentIndex + 1 >= mediaCount) {
      deviceId && navigate(`/device/${deviceId}`)
    }

    if (mediaCount > 1) {
      imageRef?.current?.classList?.add("loading");
    }
    videoRef?.current?.classList?.add("loading");

    setTimeout(() => {
      if (currentIndex + 1 >= mediaCount) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 500);
  }, [currentIndex, mediaQueue, returnAfterPlay, deviceId, navigate]);

  /**
   * Display image and start image timer
  * @param e Event
   */
  const displayImage = useCallback((e: React.ChangeEvent<HTMLImageElement>) => {
    e?.target.classList.remove("loading");

    timeout.current && clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      incrementIndex();
      clearTimeout(timeout.current);
    }, data?.content?.settings?.display?.delay || AUTOPLAY_IMAGE_TIMER);
  }, [incrementIndex, data?.content?.settings?.display?.delay]);

  /**
   * Display video when loaded
   * @param e Event
   */
  const displayVideo = useCallback((e: React.ChangeEvent<HTMLVideoElement>) => {
    e.target.classList.remove("loading");

    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, []);

  // Update video volume when global volume has changed
  useEffect(() => {
    const localVolume = (currentFile?.volume || 75) * 0.01;

    if (videoRef.current) {
      if (globalVolume !== undefined) {
        videoRef.current.volume = globalVolume * localVolume;
      } else {
        videoRef.current.volume = localVolume;
      }
    }
  }, [globalVolume, currentFile]);

  /**
   * Hide play button when video starts playing
   */
   const onPlaying = useCallback(() => {
    const localVolume = (currentFile?.volume || 75) * 0.01;

    playbuttonRef.current.classList.remove("paused");
    if (videoRef.current) {
      if (globalVolume !== undefined) {
        videoRef.current.volume = globalVolume * localVolume;
      } else {
        videoRef.current.volume = localVolume;
      }
    }
  }, [globalVolume, currentFile]);

  /**
   * Set currentTime based on click
   * @param e Event
   */
  const setProgressPosition = useCallback((e: any) => {
    if (videoRef.current && progressWrapperRef.current) {
      const clickedPositionPercentage = e.nativeEvent.offsetX / progressWrapperRef.current.clientWidth;
      const clickedPositionSeconds = videoRef.current.duration * clickedPositionPercentage;
      if (isNaN(clickedPositionSeconds)) { return }
      videoRef.current.currentTime = clickedPositionSeconds;
    }
  }, []);

  /**
   * Update progress bar when video is playing
   * @param e Event
   */
  const updateProgressBar = useCallback((e: any) => {
    if (progressRef.current) {
      const currentPosition = (e.target.currentTime / e.target.duration) * 100;
      progressRef.current.style.width = `${currentPosition}%`;
      setMediaIsPlaying(true);
    }
  }, [setMediaIsPlaying]);

  // Listen for digit keypress
  const handleUserKeyPress = useCallback((event: any) => {
    const { key } = event;
    setHideHeader(true);
    setKeyboardTriggerIsActive(true);
    removeAllSubtitles();

    for (let i = 0; i <= 9; i++) {
      if (Number(key) === i) {
        captureThumbnail();
        setCurrentIndex(i);
        if (videoRef.current) {
          videoRef.current.currentTime = 0;

        }
      }
    }
  }, [setHideHeader, setKeyboardTriggerIsActive, captureThumbnail, removeAllSubtitles]);

  // Support keypress
  useEffect(() => {
    window.addEventListener('keyup', handleUserKeyPress);
  
    return () => {
      window.removeEventListener('keyup', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // Should iframe be used instead? Check if title contains https://. Silly hack
  useEffect(() => {
    if (data?.status === "published" && data?.content?.general?.title?.no?.includes("https://")) {
      navigate(`/device/${deviceId}/iframe/?url=${encodeURIComponent(data?.content?.general?.title?.no)}`, { replace: true });
    }
  }, [data, deviceId, navigate]);

  // Display error message if no content is loaded
  if (error) { return <ErrorMessageView title={t("presentationNotFound.title")} body={t("presentationNotFound.body")} redirect={true}></ErrorMessageView> }

  // Only display if published
  if (data?.status && data?.status !== "published") { return <ErrorMessageView title={t("presentationNotPublished.title")} body={t("presentationNotPublished.body")} redirect={true}/> }

  switch(currentFile?.mediaType) {
    case "image":
    case "dm":
      return (
        <>
        <Header title={getTranslation(overrideTitle, currentLanguage) ||(data?.content?.general?.showTitle ? getTranslation(data?.content?.general?.title, currentLanguage) : "")} backgroundColor={data?.content?.background?.backgroundColor}></Header>
        <PageTransition waitFor={data}>
          <Image 
          ref={imageRef}
          src={`${currentFile?.src}?dimension=max`} 
          onLoad={displayImage}
          alt=""
          objectFit={data?.content?.settings?.display?.objectFit}
          height={contentHeight}/>
        </PageTransition>
        </>
      );
    case "video":
    case "audio":
      return (
        <>
          <Header title={getTranslation(overrideTitle, currentLanguage) ||(data?.content?.general?.showTitle ? getTranslation(data?.content?.general?.title, currentLanguage) : "")} backgroundColor={data?.content?.background?.backgroundColor}></Header>
          <PageTransition waitFor={data}>
            <PlayContainer ref={playbuttonRef}>
              { /* @ts-ignore */ }
              {keyboardTriggerIsActive && (<Screenshot preserveDrawingBuffer="true" ref={screenshotRef} objectFit={data?.content?.settings?.display?.objectFit} height={contentHeight}/>)}
              <Video autoPlay
              playsInline
              ref={videoRef}
              src={currentFile?.src} 
              loop={!returnAfterPlay && Object.keys(mediaQueue)?.length === 1}
              onPlaying={onPlaying}
              onProgress={onPlaying}
              onEnded={incrementIndex}
              onClick={playVideo}
              onTimeUpdate={updateProgressBar}
              onCanPlay={displayVideo}
              muted={previewIsActive ? true : false}
              poster={currentFile?.mediaType === "audio" ? currentFile?.thumbnail : undefined}
              height={contentHeight}
              objectFit={data?.content?.settings?.display?.objectFit}>
                {closedCaptions && (
                  <track default kind="subtitles" src={closedCaptions} />
                )}
              </Video>
              {data?.content?.settings?.display?.showProgressBar === "true" && (
                <ProgressBarWrapper ref={progressWrapperRef} onClick={setProgressPosition} className="clickable"><ProgressBar ref={progressRef} color={data?.content?.settings?.display?.progressBarColor}/></ProgressBarWrapper>
              )}
            </PlayContainer>
          </PageTransition>
        </>
      );

    case "document":
      return (
        <>
          <Header title={getTranslation(overrideTitle, currentLanguage) ||(data?.content?.general?.showTitle ? getTranslation(data?.content?.general?.title, currentLanguage) : "")} backgroundColor={data?.content?.background?.backgroundColor}></Header>
          <PageTransition waitFor={data}>
            <PdfViewer item={currentFile} autoPlay={true} autoPlayDelay={data?.content?.settings?.display?.delay} onAutoPlayEnd={incrementIndex} autoPlayLoop={Object.keys(mediaQueue)?.length === 1}/>
          </PageTransition>
        </>
      );

    case "model3d":
      return (
        <>
          <Header title={getTranslation(overrideTitle, currentLanguage) ||(data?.content?.general?.showTitle ? getTranslation(data?.content?.general?.title, currentLanguage) : "")} backgroundColor={data?.content?.background?.backgroundColor}></Header>
          <PageTransition waitFor={data}>
            <Model3dPopup item={currentFile}/>
          </PageTransition>
        </>
      );

    case "sketchfab":
      return (
        <>
          <Header title={getTranslation(overrideTitle, currentLanguage) ||(data?.content?.general?.showTitle ? getTranslation(data?.content?.general?.title, currentLanguage) : "")} backgroundColor={data?.content?.background?.backgroundColor}></Header>
          <PageTransition waitFor={data}>
            <Iframe width="100%" height="100%" src={`${currentFile?.src}?autostart=1&camera=0&annotations_visible=1&preload=1&annotation_cycle=7&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_watermark_link=0&ui_watermark=0&ui_ar=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_help=0&ui_vr=0`} sandbox="allow-scripts allow-same-origin"/>
          </PageTransition>
        </>
      );

    default:
      return <Header title={data?.content?.general?.showTitle ? getTranslation(data?.content?.general?.title, currentLanguage) : ""} backgroundColor={data?.content?.background?.backgroundColor}></Header>
  }
}

export default AutoPlayView;