import NotFoundView from "../views/404";
import ArticleView from "../views/Article";
import AutoPlayView from "../views/Autoplay";
import ImageMapView from "../views/ImageMap";
import ListView from "../views/List";
import MediaGalleryView from "../views/MediaGallery";
import PageFlipView from "../views/FlipBook";
import MosaicView from "../views/Mosaic";
import SingleView from "../views/Single";
import TimelineView from "../views/Timeline";

import { ReactComponent as HomeIcon } from "../assets/item_home.svg";
import { ReactComponent as ImageIcon } from "../assets/item_image.svg";
import { ReactComponent as PageflipIcon } from "../assets/item_image.svg";
import { ReactComponent as ImageMapIcon } from "../assets/item_imagemap.svg";
import { ReactComponent as ListIcon } from "../assets/item_list.svg";
import { ReactComponent as SingleIcon } from "../assets/item_single.svg";
import { ReactComponent as TimelineIcon } from "../assets/item_timeline.svg";
import { ReactComponent as AutoplayIcon } from "../assets/item_video.svg";
import { ReactComponent as MosaicIcon } from "../assets/item_mosaic.svg";
import { ARTICLE_SCHEMA_ID, PRESENTATION_SCHEMA_ID } from "../Settings";

/**
 * Get presentation component
 * @param presentationType Presentation type. Examples: list, timeline, imagemap, etc
 * @param formData Preview data to be displayed. Optional
 * @returns JSX Component
 */
export const getPresentationComponent = (presentationType: string, formData?: any, overrideTitle?: string) => {
  switch(presentationType) {
    case "article":
      return <ArticleView previewData={formData} overrideTitle={overrideTitle}/>
    case "single":
      return <SingleView previewData={formData} overrideTitle={overrideTitle}/>
    case "list":
      return <ListView previewData={formData} overrideTitle={overrideTitle}/>
    case "timeline":
      return <TimelineView previewData={formData} overrideTitle={overrideTitle}/>
    case "imagemap":
      return <ImageMapView previewData={formData} overrideTitle={overrideTitle}/>
    case "mosaic":
      return <MosaicView previewData={formData} overrideTitle={overrideTitle}/>
    case "gallery":
      return <MediaGalleryView previewData={formData} overrideTitle={overrideTitle}/>
    case "flipbook":
      return <PageFlipView previewData={formData} overrideTitle={overrideTitle}/>
    case "autoplay":
      return <AutoPlayView previewData={formData} overrideTitle={overrideTitle}/>
    default:
      return <NotFoundView/>
  }
}

/**
 * Get schema and content type based on API data
 * @param {object} data API data
 * @returns {string} Type of content (article, )
 */
export const getContentType = (data) => {
  switch(data?.schema_id) {
    case PRESENTATION_SCHEMA_ID:
      return data?.content?.general?.presentationType;
    case ARTICLE_SCHEMA_ID:
      return "article";
  }
}

/**
 * Insert placeholder icon
 * @param iconName Name of icon
 * @returns 
 */
export const getIcon = (iconName: string) => {
  let iconImage;

  switch (iconName) {
    case "article":
    case "single":
      iconImage = <SingleIcon/>
      break;
    case "timeline":
      iconImage = <TimelineIcon/>
      break;
    case "imagemap":
      iconImage = <ImageMapIcon/>
      break;
    case "list":
      iconImage = <ListIcon/>
      break;
    case "pageflip":
      iconImage = <PageflipIcon/>
      break;
    case "gallery":
      iconImage = <ImageIcon/>
      break;
    case "mosaic":
      iconImage = <MosaicIcon/>
      break;
    case "autoplay":
      iconImage = <AutoplayIcon/>
      break;
    case "home":
      iconImage = <HomeIcon/>
      break;
    default:
      iconImage = <SingleIcon/>;
      break;
  }

  return iconImage;
}

/**
 * Detect DOM changes for a specified ref
 * @param ref Reference to object to observe
 * @param callback Callback function
 */
export const detectDomChanges = (ref: any, callback: () => void) => {
  if (ref?.current) {
    let observer = new MutationObserver((data) => {
      callback && callback();
    });
    observer.observe(ref?.current, {
      childList: true,
      subtree: true
    });
  }
}

/**
 * Check if webp image format is supported
 * @returns {boolean}
 */
export const webpIsSupported = () => {
  const img = new Image();
  img.src = 'data:image/webp;base64,UklGRjIAAABXRUJQVlA4ICYAAACyAgCdASoCAAEALmk0mk0iIiIiIgBoSygABc6zbAAA/v56QAAAAA==';

  if (img.width > 0) {
    return true;
  } else {
    return false;
  }
}