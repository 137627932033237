import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { getImageDimensions, getImageUrl, parseCredits } from '../common/Media';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { getTranslation } from '../common/Translation';
import { AppContext } from '../contexts/AppContext';
import PopupVideo from './LightboxModal_Video';
import PopupAudio from './LightboxModal_Audio';
import Popup3D from './LightboxModal_3D';
import 'photoswipe/dist/photoswipe.css';
import PdfViewer from './PdfViewer';
import { MEDIA_PREFIX } from '../Settings';

interface LightboxWrapperProps {
  children: JSX.Element | JSX.Element[],
  options?: any
}

interface LightboxItemProps {
  media?: any,
  caption?: string,
  credits?: string,
  children: JSX.Element
}

/**
 * Render a lightbox wrapper
 * @returns {JSX.Element} Component template
 */
export const LightboxWrapper: FC<LightboxWrapperProps> = ({children, options}) => {
  const { setMediaIsPlaying } = useContext(AppContext);

  /**
   * On Photoswipe initiation
   */
  const pswpInit = useCallback((pwsp: any) => {
    // Slide is changed
    pwsp.on("change", () => {
      setMediaIsPlaying(false);

      const captionElement = document.querySelector(".pswp__default-caption") as HTMLDivElement;
      if (captionElement) { captionElement.style.display = "block"; }
    });

    // Lightbox is closed
    pwsp.on("close", () => {
      setMediaIsPlaying(false);
    });
  }, [setMediaIsPlaying])

  return (
    <>
      <Gallery withCaption onOpen={pswpInit} options={{...{options}, ...{showHideAnimationType: 'fade'}}}>
        {children}
      </Gallery>
    </>
  );
}

/**
 * Render a lightbox item
 * @returns {JSX.Element} Component template
 */
export const LightboxItem: FC<LightboxItemProps> = ({media, children, credits, caption}) => {
  const { currentLanguage } = useContext(AppContext);
  const [mediaDimensions, setMediaDimensions] = useState<any>();
  const [mediaObject, setMediaObject] = useState<any>();
  const [modalCaption, setModalCaption] = useState<string>("");

  // Put media in state to prevent rerenders
  useEffect(() => {
    if (!mediaObject && media?.url) {
      setMediaObject(media);
    }
  }, [media, mediaObject]);

  /**
   * Convert plain text into HTML with line breaks
   */
  const makeLineBreaks = useCallback((str: string) => {
    return str?.trim()?.replace(/(?:\r\n|\r|\n)/g, '<br/>');
  }, []);

  // Get captions
  useEffect(() => {
    if (mediaObject?.title || mediaObject?.description || mediaObject?.credits?.length > 0 || caption || credits) {
      setModalCaption(`<strong>${mediaObject?.title ? makeLineBreaks(getTranslation(mediaObject?.title, currentLanguage)) : caption || ""}</strong>
      ${mediaObject?.description ? "<br/>" + makeLineBreaks(getTranslation(mediaObject?.description, currentLanguage)) : ""}
      <br/>${parseCredits(mediaObject?.credits)}`);
    }
  }, [mediaObject, caption, credits, currentLanguage, makeLineBreaks]);

  /**
   * Close popup by simulating click on close button
   */
  const closeModal = useCallback(() => {
    const closeButton = document.querySelector(".pswp__button--close") as HTMLButtonElement;
    closeButton && closeButton.click();
  }, []);

  // Get media dimensions due to demands from PhotoSwipe
  useEffect(() => {
    let isSubscribed = true;

    if (mediaObject?.url) {
      getImageDimensions(`${MEDIA_PREFIX}${mediaObject?.thumbnail_src || mediaObject?.url}`).then(object => {
        isSubscribed && setMediaDimensions(object);
      }).catch(e => {});
    }
    return () => { isSubscribed = false };
  }, [mediaObject]);

  // Render lightbox item
  switch(mediaObject?.media_type) {
    case "video":
      return (
        <Item content={<PopupVideo item={mediaObject} close={closeModal} caption={modalCaption}/>}>
          {({ ref, open }) => (
            <>
              { React.cloneElement(children, { ref: ref as React.RefObject<HTMLDivElement>, onClick: open }) }
            </>
          )}
        </Item>
      );

    case "audio":
      return (
        <Item content={<PopupAudio item={mediaObject} close={closeModal} caption={modalCaption}/>}>
          {({ ref, open }) => (
            <>
              { React.cloneElement(children, { ref: ref as React.RefObject<HTMLDivElement>, onClick: open }) }
            </>
          )}
        </Item>
      );

    case "image":
    case "dm":
      return (
        <Item original={MEDIA_PREFIX + getImageUrl(mediaObject?.url, { dimension: "3500" })} thumbnail={MEDIA_PREFIX + getImageUrl(mediaObject?.url, { dimension: "600x600" })} width={mediaDimensions?.width * 3|| 0} height={mediaDimensions?.height * 3|| 0} caption={modalCaption}>
          {({ ref, open }) => (
            <>
              { React.cloneElement(children, { ref: ref as React.RefObject<HTMLDivElement>, onClick: open}) }
            </>
          )}
        </Item>
      );

    case "model3d":
      return (
        <Item content={<Popup3D item={mediaObject} close={closeModal} />}>
          {({ ref, open }) => (
            <>
              {React.cloneElement(children, { ref: ref, onClick: open, onKeyDown: (e: any) => { e?.code === "Enter" && open(e); }})}
            </>
          )}
        </Item>
      );

    case "document":
      return (
        <Item content={<PdfViewer item={mediaObject} close={closeModal} caption={modalCaption}/>}>
          {({ ref, open }) => (
            <>
              { React.cloneElement(children, { ref: ref as React.RefObject<HTMLDivElement>, onClick: open }) }
            </>
          )}
        </Item>
      );

    case "sketchfab":
      return (
        <Item html={`<iframe width="100%" height="80%" style="max-height: 80%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); border: none;" src="${mediaObject?.src}?ui_infos=0&ui_hint=0&ui_watermark_link=0&ui_watermark=0&ui_inspector=0&ui_settings=0&ui_help=0&ui_vr=0&ui_stop=0&ui_fullscreen=0&ui_theme=dark&autostart=1&preload=1" poster="${mediaObject?.thumbUrl}" sandbox="allow-scripts allow-same-origin"/>`} caption={`<strong>${getTranslation(mediaObject?.title, currentLanguage)}</strong><br/>${parseCredits(mediaObject?.credits)}`}>
          {({ ref, open }) => (
            <>
              {React.cloneElement(children, { ref: ref as React.RefObject<HTMLLIElement>, onClick: open, onKeyDown: (e: any) => { e?.code === "Enter" && open(e); }})}
            </>
          )}
        </Item>
      );

    default:
      return (
        <>
          {children}
        </>
      );
  }
}