import Kulturio from '@ekultur/kulturio-bridge';
import { verifyWebpSupport } from './common/Media';

// Kulturio backend
let apiUrl = process.env.REACT_APP_KULTURIO_API_URL || "";
let apiKey = process.env.REACT_APP_KULTURIO_API_KEY || "";
let articleSchema = parseInt(process.env.REACT_APP_SCHEMA_ARTICLE || "");
let presentationSchema = parseInt(process.env.REACT_APP_SCHEMA_KIOSK_PRESENTATION || "");
let deviceSchema = parseInt(process.env.REACT_APP_SCHEMA_KIOSK_DEVICE || "");

// ---- Testing purposes start ----

const urlParams = new URLSearchParams(window.location.search);
const env = urlParams.get('env');
if (env) { localStorage.setItem("env", env); }

// Schema IDs - dev instance
if (localStorage.getItem("env") === "dev") {
  apiUrl = "https://dev-admin.kulturio.org/portal/api/";
  apiKey = "9bc113be-d253-4beb-955e-94a80c59a374";
  articleSchema = 2;
  presentationSchema = 30;
  deviceSchema = 29;
}

// Schema IDs - test instance
if (localStorage.getItem("env") === "test") {
  apiUrl = "https://dev-admin.kulturio.org/portal/api/";
  apiKey = "f17ee8eb-ada1-442e-8147-cb339f9463f4";
  articleSchema = 5;
  presentationSchema = 3;
  deviceSchema = 4;
}

// Schema IDs - beta instance
if (localStorage.getItem("env") === "beta") {
  apiUrl = "https://beta-admin.kulturio.org/portal/api/";
  apiKey = "9bc113be-d253-4beb-955e-94a80c59a374";
  articleSchema = 2;
  presentationSchema = 30;
  deviceSchema = 29;
}

// Schema IDs - course instance
if (localStorage.getItem("env") === "kurs") {
  apiUrl = "https://kurs-admin.kulturio.org/portal/api/";
  apiKey = "761bb3cf-86f8-4df5-b8f9-418578b92147";
  articleSchema = 1;
  presentationSchema = 3;
  deviceSchema = 4;
}

// Schema IDs - production
if (localStorage.getItem("env") === "prod" || Kulturio.getConfig("interface.environment") === "production") {
  apiUrl = "https://admin.kulturio.org/portal/api/";
  apiKey = "06610301-1032-4b19-9197-6af77e8aebd6";
  articleSchema = 2;
  presentationSchema = 13;
  deviceSchema = 14;
}

// Schema IDs - local
if (localStorage.getItem("env") === "local") {
  apiUrl = "http://localhost:5000/portal/api/";
  apiKey = "06610301-1032-4b19-9197-6af77e8aebd6";
  articleSchema = 2;
  presentationSchema = 13;
  deviceSchema = 14;
}

export const KULTURIO_API_KEY = apiKey;
export const KULTURIO_API_URL = apiUrl;
export const ARTICLE_SCHEMA_ID = articleSchema;
export const PRESENTATION_SCHEMA_ID = presentationSchema;
export const DEVICE_SCHEMA_ID = deviceSchema;

// ---- Testing purposes end ----

// Environment
export const KULTURIO_ENV = process.env.REACT_APP_ENVIRONMENT;

// Timers
export const DEFAULT_SCREEN_TIMEOUT = 60000;
export const AUTOPLAY_IMAGE_TIMER = 20000;

// Languages, ISO 639-1
export const LANGUAGES = [
  { key: "no", name: "Norsk", icon: "./img/lang_no.png", siblings: ["nb_no", "nn_no", "nb", "no"], fallback: ["sv", "en"] },
  { key: "nb", name: "Norsk bokmål", icon: "./img/lang_no.png", siblings: ["no", "nb_no", "nn_no"]},
  { key: "nn", name: "Norsk nynorsk", icon: "./img/lang_no.png", siblings: ["no", "nn_no", "nb_no"] },
  { key: "sv", name: "Svenska", icon: "./img/lang_sv.png", siblings: ["sv_se"] },
  { key: "se", name: "Davvisámegiella", icon: "./img/lang_sami.png" },
  { key: "sma", name: "Åarjelsaemien", icon: "./img/lang_sami.png" },
  { key: "fk", name: "Kvääni", icon: "./img/lang_sami.png" },
  { key: "en", name: "English", icon: "./img/lang_en.png" },
  { key: "de", name: "Deutsch", icon: "./img/lang_de.png" },
  { key: "fr", name: "Français", icon: "./img/lang_fr.png" },
  { key: "es", name: "Español", icon: "./img/lang_es.png"},
  { key: "pl", name: "Polski", icon: "./img/lang_pl.png"}
];

// Credits
export const CREDITS = {
  1: "credit.photographer",
  2: "credit.artist",
  3: "credit.author",
  4: "credit.director",
  5: "credit.voice",
  6: "credit.owner",
  7: "credit.rightsmanager",
  8: "credit.license",
  9: "credit.descriptionWriter",
  10: "credit.producer",
  11: "credit.recordCompany",
  12: "credit.composer",
  13: "credit.copyrightOwner",
  14: "credit.creator",
  15: "credit.creatorJobtitle",
  16: "credit.creatorContactInfo",
  17: "credit.creditLine",
  18: "credit.copyrightNotice",
  19: "credit.creditLine",
  20: "credit.lyrics"
}

// Media url prefix. Used for caching in the Kulturio apps
let api_prefix = "", media_prefix = "";
if (window.navigator.userAgent === "Kulturio Kiosk iOS App") {
  console.info("Running in Kulturio iOS App");
  api_prefix = `http://localhost:7000/cache/`;
  media_prefix = `http://localhost:7000/cache/`;
}

// Kulturio desktop app
if (Kulturio.inKulturioApp()) {
  console.info("Running in Kulturio Desktop App");
  const port = Kulturio.getConfig("port") || 7000;

  api_prefix = `http://localhost:${port}/portal/`;
  media_prefix = `http://localhost:${port}/media/`;
}
export const API_PREFIX = api_prefix;
export const MEDIA_PREFIX = media_prefix;
export const WEBP_SUPPORT = verifyWebpSupport();

// DM
export const DM_DMS_IMAGE_URL = "https://ems.dimu.org/image/{id}";
export const DM_DMS_VIDEO_URL = "https://ems.dimu.org/streaming/progressive/{id}.mp4?mmid={id}";
export const DM_DMS_AUDIO_URL = "https://ems.dimu.org/multimedia/{id}.mp3?mmid={id}";
export const DM_DISPLAY_URL = "https://digitaltmuseum.org/{id}";
export const DM_SEARCH_URL = "https://api.dimu.org/api/solr/";
export const DM_OBJECT_URL = "https://api.dimu.org/api/artifact?unique_id={id}&mapping=simple_json"
export const DM_API_KEY = "BAFLAwH5QE6FkhfUwFNksg";

// Minne API
export const MINNE_API_URL_NO = "https://api.minner.no/api/";
export const MINNE_API_URL_SV = "https://api.minnen.se/api/";